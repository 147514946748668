import React, { useEffect } from "react";

import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";

import PapaHead from "../../assets/papahead404.svg";

const Page404 = () => {
  useEffect(() => {
    const el = document.getElementById("app__content");
    el.classList.add("page-404");
    return () => {
      el.classList.remove("page-404");
    };
  }, []);

  return (
    <>
      <PageHeader className="text-center flex direction-column">
        <div className="papa-head flex">
          <span>4</span>
          <img src={PapaHead} alt="Papa" />
          <span>4</span>
        </div>

        <h1>Sorry, Page Not Found </h1>
        <h2>Please go back to the previous page or go to the homepage.</h2>

        <Button onClick={() => {
          window.location.href = "https://www.papa.com";
        }}>Back to home</Button>
      </PageHeader>
    </>
  );
};

export default Page404;
