import { US_STATES } from "./states";

export function textToSlug(value: string) {
  const slug = value.toLowerCase().replaceAll(" ", "-");
  return slug;
}

export function slugToText(value: string) {
  const slug = value.toLowerCase().replaceAll("-", " ").split(" ");
  return slug
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
}

export function chunkArray(array: any[], size: number) {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
}

export function capitalize(value: string) {
  const s = value.toLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function slugify(value: string | undefined) {
  if (!value) return null;
  return value.replaceAll(/\s+/g, "-").toLowerCase();
}

export function getState(value: string) {
  return US_STATES.find(
    (item) => item.name.replaceAll(/\s+/g, "-").toLowerCase() === value
  );
}

export function formatPhone(value: string | null) {
  if (!value) return "";

  //Filter only numbers from the input
  const cleaned = ("" + value).replace(/\D/g, "");

  //Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
}

export function formatPhoneAlt(value: string | null) {
  if (!value) return "";

  const cleaned = ("" + value).replace(/\D/g, "");

  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return [match[2], "-", match[3], "-", match[4]].join("");
  }

  return null;
}
