import React from "react";

import PapaHeadSmall from "../../assets/papa-head-small.svg";

const LoadingScreen = () => {
  return (
    <div className="text-center">
      <img src={PapaHeadSmall} alt="Papa head" />
    </div>
  );
};

export default LoadingScreen;
