import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Logo from 'assets/logo-v2.svg'

const PapaHomeUrl = 'https://www.papa.com'

const Navbar: React.FunctionComponent = () => {
  const [t] = useTranslation('translation', { keyPrefix: 'nav' })
  return (
    <nav className="navbar flex">
      <div className="container flex align-center">
        <div className="navbar__brand">
          <a className="row align-items" href={PapaHomeUrl}>
            <img src={Logo} alt="Papa Logo" className="brand" />
          </a>
        </div>

        <div className="navbar__nav flex responsive-nav-menu">
          <Link to="/pals/signup" className="nav-link">
            {t('signup')}
          </Link>
          <a href={`${PapaHomeUrl}/enterprise`} className="nav-link">
            {t('enterprise')}
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
