// Tracking in single-page applications: https://developers.hubspot.com/docs/api/events/tracking-code

const _hsq = window._hsq = window._hsq || [];

export const setInitialHubSpotPagePath = (history) => {
  try {
    const pathname = history?.location?.pathname;
    _hsq.push(['setPath', pathname]);
  } catch (e) {
    console.warn(e);
  }
}

export const trackSubsequentHubSpotPageView = (location) => {
  try {
    const pathname = location.pathname;
    _hsq.push(['setPath', pathname]);
    _hsq.push(['trackPageView']);
  } catch (e) {
    console.warn(e);
  }
}