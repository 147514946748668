import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import cx from "classnames"
import ScrollToTop from "ScrollToTop"

import Page404 from "../containers/Responses/Page404"
import { routes } from "./routes"

function lazyLoad(component: string) {
  return React.lazy(() => import(`../containers/${component}`))
}

const PalOnboarding = lazyLoad("PalOnboarding")
const VisitRating = lazyLoad("VisitRating")
const PalDisbursementProcessorOnboardingSuccessPage = lazyLoad(
  "PalDisbursementProcessorOnboardingSuccessPage"
)

const pathWithRoot = (path: string) => {
  return routes.pals.signup.path + path
}

const Routes: React.FC = () => {
  const { pathname } = useLocation()

  const classes = cx({
    "app-padding": !pathname.includes(routes.pals.signup.path),
  })

  return (
    <div id="app__content" className={classes}>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={[
              routes.pals.signup.path,
              `${routes.pals.signup.path}?phone=:phone`,
              `${routes.pals.signup.path}?code=:code&phone=:phone`,
            ]}
          >
            <PalOnboarding />
          </Route>
          <Route
            exact
            path={[
              routes.pals.signup.palDisbursementProcessorOnboardingSuccess.path,
            ]}
          >
            <PalDisbursementProcessorOnboardingSuccessPage />
          </Route>
          <Route
            exact
            path={[
              pathWithRoot(routes.rateYourVisit.show.path),
              pathWithRoot(routes.calificaTuVisita.show.path),
            ]}
            component={VisitRating}
          />

          <Route
            exact
            path={routes.palSupport.path}
            component={() => {
              window.location.href =
                "https://joinpapa.atlassian.net/servicedesk/customer/portals"
              return null
            }}
          />

          <Route
            exact
            path={routes.help.path}
            component={() => {
              window.location.href =
                "https://joinpapa.notion.site/Welcome-to-the-Pal-Content-Hub-08b4397cecb74fbc8323b51bade4d53f"
              return null
            }}
          />
          <Route exact path="/">
            <Redirect to={routes.pals.signup.path} />
          </Route>
          <Route component={Page404} />
        </Switch>
      </ScrollToTop>
    </div>
  )
}

export default Routes
