import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import * as reactI18n from "react-i18next";
import dayjs from "dayjs"
import { formatPhone } from "utils/helpers";

i18next
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(reactI18n.initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: false,
    backend: {
        loadPath: "/pals/signup/locales/{{lng}}/{{ns}}.json"    
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format)
      {
        if (format === "phoneNumberFormat") return formatPhone(value)
        if (format === "capitalize") return value.charAt(0).toUpperCase() + value.slice(1);
        if (value instanceof Date) return dayjs(value).format(format);
        return value;
      },
    },

    detection: {
      order: ["querystring", "localStorage", "cookie", "navigator", "htmlTag", "path", "subdomain"],
      caches: ["localStorage"],
    },
  });

export default i18next;