import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import PropTypes from "prop-types"

import LoadingIcon from "../assets/circle-notch-solid.svg"

const Button: React.FC<any> = ({
  variant,
  loading,
  className,
  children,
  ...props
}) => {
  function getComponentType() {
    if (props.href) return "a"
    if (props.to) return Link
    return "button"
  }

  const classes = classNames("button", className, {
    [`button--${variant}`]: variant,
    "button--loading": loading,
    "button--disabled": props.disabled || loading,
  })

  const Component = getComponentType()

  return (
    <Component {...props} className={classes}>
      {children}{" "}
      {loading && (
        <img src={LoadingIcon} alt="Loading" className="loading-icon" />
      )}
    </Component>
  )
}

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"]),
  loading: PropTypes.bool,
}

Button.defaultProps = {
  variant: "primary",
  loading: false,
}

export default Button
