import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "lazysizes";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";

// eslint-disable-next-line
import "./utils/fetch";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://989e81a7e2f943648080c6f3f16391d5@sentry.io/2230564",
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
