import React from "react";
import classNames from "classnames";

const Container: React.FC<any> = ({ fluid, flex, className, children }) => {
  const classes = classNames("container", className, {
    "container-fluid": fluid,
    flex: flex,
  });

  return <div className={classes}>{children}</div>;
};

export default Container;
