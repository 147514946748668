import React, { useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history"

type Props = {
  history: History
}

const ScrollToTop: React.FC<Props> = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <Fragment>{children}</Fragment>;
};

export default withRouter(ScrollToTop);
