import React from 'react'
import Container from './Container'

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <hr />
        <div className="legal flex">
          <div className="mr-30">Papa Inc © {new Date().getFullYear()}</div>
          <div>
            <>
              <a
                href="https://joinpapa.notion.site/Welcome-to-the-Pal-Content-Hub-08b4397cecb74fbc8323b51bade4d53f"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pal FAQ
              </a>{' '}
              |{' '}
            </>
            <a href="https://www.papa.com/terms-of-use">Terms of Service</a> |{' '}
            <a href="https://www.papa.com/privacy-policy">Privacy Policy</a> |{' '}
            <a href="https://joinpapa.ethicspoint.com/" target="_blank" rel="noopener noreferrer">
              Ethics and Conduct Reporting
            </a>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
