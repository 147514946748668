import { route } from "static-route-paths"
export const routes = route({
  root: route(),

  pals: route("pals", {
    signup: route("signup", {
      palDisbursementProcessorOnboardingSuccess: route(
        "pal-disbursement-processor-onboarding-success"
      ),
    }),
  }),
  palSupport: route("palsupport"),
  help: route("help"),
  rateYourVisit: route("rate-your-visit", {
    show: route(":id"),
  }),
  calificaTuVisita: route("califica-tu-visita", {
    show: route(":id"),
  }),
})
