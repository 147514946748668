import React, { Suspense } from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { client } from "config/client";

import LoadingScreen from "./containers/LoadingScreen/LoadingScreen";
import CookiesBanner from "./components/CookiesBanner";
import Footer from "./components/Footer";

import "./stylesheets/app.scss";
import Routes from "appRouter";

import {
  setInitialHubSpotPagePath,
  trackSubsequentHubSpotPageView,
} from "./utils/hubspotTracking";
import { CustomWindow } from "types/window";
import { I18nextProvider } from "react-i18next";
import i18next from "./i18n";

const history = createBrowserHistory();
history && setInitialHubSpotPagePath(history);

// Listen and notify Segment and Hubspot of client-side page updates
history.listen((location) => {
  (window as CustomWindow).analytics &&
    (window as CustomWindow).analytics.page();
  location && trackSubsequentHubSpotPageView(location);
});

function App() {
  return (
    <div id="app">
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18next}>
          <Suspense fallback={<LoadingScreen />}>
            <Router>
              <Routes />
              <Footer />
              <CookiesBanner />
            </Router>
          </Suspense>
        </I18nextProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
