import merge from "deepmerge";
import "whatwg-fetch";

window.csrfToken = "";
const metaElem = document.querySelector("meta[name=csrf-token]");
if (metaElem) window.csrfToken = metaElem.getAttribute("content");

window.CSRFFetch = function CSRFFetch(url, options = {}) {
  if (!(options.body instanceof FormData)) {
    options.body = JSON.stringify(options.body);
  }

  if (options.method === "GET") {
    options.body = undefined;
  }

  let mergedOptions = options;
  return fetch(url, mergedOptions);
};

window.jCSRFFetch = function jCSRFFetch(url, params = {}) {
  const options = params;

  return window
    .CSRFFetch(
      url,
      merge(
        {
          headers: merge(
            { Accept: "application/json" },
            options.body instanceof FormData
              ? {}
              : { "Content-Type": "application/json" }
          )
        },
        options
      )
    )
    .then(response => {
      if (response.status === 204) {
        return { json: {}, response };
      }

      return response.json().then(json => ({ json, response }));
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject({
          status: response.status,
          data: json
        });
      }

      return {
        status: response.status,
        data: json
      };
    });
};

window.get = function get(url, options = {}) {
  return window.jCSRFFetch(url, merge(options, { method: "GET" }));
};

window.post = function post(url, options = {}) {
  return window.jCSRFFetch(url, merge(options, { method: "POST" }));
};

window.put = function put(url, options = {}) {
  return window.jCSRFFetch(url, merge(options, { method: "PUT" }));
};

window.patch = function patch(url, options = {}) {
  return window.jCSRFFetch(url, merge(options, { method: "PATCH" }));
};

window.remove = function remove(url, options = {}) {
  return window.jCSRFFetch(url, merge(options, { method: "DELETE" }));
};

