import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { datadogRum } from '@datadog/browser-rum'

// @ts-expect-error
if (process.env.REACT_APP_DATADOG_RUM_ENABLED.toLowerCase() === 'true') {
  const allowedTracingOrigins =
    process.env.REACT_APP_DATADOG_RUM_APM_TRACE_ENABLED?.toLowerCase() === 'true'
      ? process.env.REACT_APP_BACKEND_API_URL?.replace('/api/graphql', '')
      : ''

  datadogRum.init({
    // @ts-expect-error
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_KEY,
    // @ts-expect-error
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'pal-signup-web',
    env: process.env.REACT_APP_ENV === 'prod' ? 'production' : process.env.REACT_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    // @ts-expect-error
    allowedTracingOrigins: [allowedTracingOrigins],
  })

  datadogRum.startSessionReplayRecording()
}

export const authHeader = () => {
  const token = localStorage.getItem('palToken')
  if (!token) return undefined

  return { authorization: `Bearer ${token}` }
}

const httpLink = () => {
  if (process.env.REACT_APP_ENV === 'prod') {
    return new HttpLink({
      uri: process.env.REACT_APP_BACKEND_API_URL,
      credentials: 'include',
    })
  } else {
    return new HttpLink({
      uri: process.env.REACT_APP_BACKEND_API_URL,
    })
  }
}

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      'app-name': 'Pal Sign Up',
      ...headers,
      ...authHeader(),
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink()),
  cache: new InMemoryCache(),
})
