import React, { useState } from 'react'

import Container from './Container'
import Button from './Button'

const PapaHomeUrl = 'https://www.papa.com'

const CookiesBanner = () => {
  const [accepted, setAccepted] = useState(false)

  function handleClick() {
    localStorage.setItem('cookiesAccepted', 'true')
    setAccepted(true)
  }

  if (accepted || localStorage.cookiesAccepted) {
    return null
  }

  return (
    <div className="banner">
      <Container>
        <div className="flex align-center space-between">
          <div className="banner__content mr-30">
            <div>
              We use cookies to improve your experience on our site. To find out more, read our{' '}
              <a href={`${PapaHomeUrl}/privacy-policy`}>privacy policy</a> and{' '}
              <a href={`${PapaHomeUrl}/terms-of-use`}>terms of service</a>.{' '}
            </div>
          </div>
          <Button onClick={handleClick}>I Agree</Button>
        </div>
      </Container>
    </div>
  )
}

export default CookiesBanner
