import React from "react";
import classNames from "classnames";

import Navbar from "./Navbar";
import Container from "./Container";

const PageHeader: React.FC<any> = ({
  image,
  navbar,
  children,
  className,
  customPageHeaderClass,
}) => {
  return (
    <div
      className={classNames(
        customPageHeaderClass !== undefined
          ? customPageHeaderClass
          : "page-header",
        className
      )}
      {...(image && {
        style: {
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
        },
      })}
    >
      {image && <div className="image-filter" />}
      <Navbar />

      <Container>{children}</Container>
    </div>
  );
};

export default PageHeader;
